import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout";
const imageWrappedPickles = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/wrightbrand-the-bacon-wrappedpickle-recipe-1367x737px.jpg';

const RecipePage = () => (
    <Layout metaTitle="Bacon Wrapped Pickles"
        metaDescription="Experience a unique flavor fusion with our
        Bacon Wrapped Pickles recipe. A perfect
        appetizer combining the crunch of pickles
        and savory taste of bacon!"
        metaKeywords=""
    >
        <meta property="og:image" content={imageWrappedPickles} />
        <link rel="image_src" href={imageWrappedPickles} />
        <div id="recipe-details-wrapper" >
            <div class="image">
                <img src={imageWrappedPickles} class='img-responsive' alt="The Bacon Wrapped Pickle" />
            </div>

            <div class="inner-wrapper">
                <div id="recipe-details">
                    <div class="container">
                        <div class="content-wrapper">
                            <h1>The Bacon Wrapped Pickle</h1>

                            <div class="recipe-content">
                                <p >
                                    In a pickle for what appetizer to serve? Just wrap one in our bacon.<br></br>
                                     By: Redneck Culinary Academy - Wichita County, TX<br></br>
                                     Featured at Bacon City USA - Food Truck Competition 2023
                                </p>
                            </div>
                        </div>

                        <div class="timing row">
                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/prepIcon.png" alt="Prep Time" />
                                </div>
                                <div class="details">
                                    <h4>Prep Time:</h4>
                                    <p>
                                        15&nbsp;minutes                           </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/cookIcon.png" alt="Cook Time" />
                                </div>
                                <div class="details">
                                    <h4>Cook Time:</h4>
                                    <p>
                                        45&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/serveIcon.png" alt="Serving Size" />
                                </div>
                                <div class="details">
                                    <h4>Serving Size:</h4>
                                    <p>1</p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="ingredients-wrapper recipe-section">
                    <div class="container">


                        <h2>Ingredients</h2>
                        <div class="row ingredients">
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 package of Wright® Brand, Applewood Smoked Bacon
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                sweet BBQ rub             
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                spicy BBQ rub
                               
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 jar of pickle spears
                              
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                Ranch or other dipping sauce
                               
                </div>
                        </div>

                    </div>
                </div>

                <div class="instructions-wrapper recipe-section">
                    <div class="container">


                        <h2>Directions</h2>
                        <div class="instructions">
                            <p>
                                1. Wrap each pickle individual pickle with 1 slice of Wright ® Brand, Thick-Cut Bacon.

                </p>
                            <p>
                                2. Season both sides of wrapped pickle.
                </p>
                            <p>
                                3. Place on your smoker, cook until temperature reaches 160°F.
                </p>
                            <p>
                                4. Remove from smoker, glaze with your favorite dipping sauce & enjoy. 
                </p>
                        </div>

                        <div></div>
                    </div>
                </div>
            </div>
        </div>


        <article class="locator-callout">
            <div class="quick-form-container">


                <div class="product-locator-search">
                    <h2>Find Our Products</h2>
                    <form data-url="/locator/" action="/locator/" method="get">
                        <input Value="ANY_ANY" id="PROD" name="PROD" type="hidden" value="ANY_ANY" />
                        <div class="input-wrapper">
                            <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                            <input type="submit" value="Locate Bacon"></input>
                            <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                        </div>
                    </form>
                </div>

            </div>
        </article>
    </Layout>
)

export default RecipePage
